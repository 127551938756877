"use strict";

import onInitModulesInsScope from "./onInitModulesInScope";

export function init() {
    onInitModulesInsScope(function initInScope($scope) {
        let $sliders = $scope.find('.js-mini-slider');

        import('slick-carousel').then(function() {
            $sliders.each(function() {

                let $slider = $(this);
                let $sliderWrapper = $slider.closest('.mini-slider__wrapper');

                $slider.slick({
                    dots: true, /*should always be true; hide with css (accessibility)*/
                    arrows:true,
                    slidesToShow: 1,
                    infinite:false,
                    speed: 900,
                    cssEase: 'ease',
                    prevArrow: $sliderWrapper.find('.slider-arrow--small.slider-arrow--outlines.arrow-prev'),
                    nextArrow: $sliderWrapper.find('.slider-arrow--small.slider-arrow--outlines.arrow-next')
                });

            });
        })
    })
}