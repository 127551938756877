import $ from 'jquery';
import './scripts/common';
import './scripts/common-main-site';
import onInitModulesInScope from "./scripts/onInitModulesInScope";

jQuery.event.special.touchstart = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.touchmove = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.wheel = {
    setup: function( _, ns, handle ){
        this.addEventListener("wheel", handle, { passive: true });
    }
};
jQuery.event.special.mousewheel = {
    setup: function( _, ns, handle ){
        this.addEventListener("mousewheel", handle, { passive: true });
    }
};

if (!window.HTMLPictureElement) {
    import('picturefill');
}


let _config = window['_config'] || {};

window.app = window['app'] || {};
window.app.modules =  app.modules || {};

import initModulesInScope from "@elements/init-modules-in-scope";
app.initModules = function ($scope) {
    console.warn('\'app.initModules\' is going to be deprecated and should not be used anymore. Please import and use \'initModulesInScope\' from \'./scripts/initModulesInScope\' instead.');
    initModulesInScope($scope);
};

$.loadScript = function (url) {
    console.warn('\'$.loadScript\' is going to be deprecated and should not be used anymore. Please use \'import\' for local files or import and use \'loadScript\' for external resources.');

    return import('@elements/load-script').then(function (loadScript) {
        return loadScript.default(url);
    });
};

/**********************************
 *
 *      Portal Stuff
 *
 * ********************************/

let selectors = {
    base: '.js-lightbox',
    item: '.js-lightbox__item'
};

import * as lightbox from '@elements/lightbox';
onInitModulesInScope(lightbox.createInitInScope(selectors, {
    iframeMaxWidth: '75%'
}));


import * as miniSlider from './scripts/mini-slider';
miniSlider.init();

import * as touchNav from '@elements/touch-nav';
import * as accessibilityNav from '@elements/accessibility-nav';
if(matchMedia('(min-width: 768px)').matches){
    onInitModulesInScope(accessibilityNav.initInScope);
    onInitModulesInScope(touchNav.initInScope);
}


import * as specialTeasers from './scripts/specialTeasers';
specialTeasers.init();

import * as svgConverter from './scripts/svgConverter';
svgConverter.init();

import * as floatingLabels from '@elements/floating-labels';
onInitModulesInScope(floatingLabels.initInScope);


(function( $ ){
    "use strict";

    /* -> _config._preload = Load this functions first */
    if (_config['_preload']) {
        $.each( _config['_preload'], function( _key, _val ){
            if( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ){
                window.app[_key]();
            }
        });
    }

    /* -> _config = Load all others (not _preload and _reload) */
    $.each( _config, function( _key, _val ){
        if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' && _key != '_reload' && _key != '_preload' ) ){
            window.app[_key]();
        }
    });

    /* -> _config._reload = Load the ajaxInclued and others after the rest */
    if (_config['_reload']) {
        $.each( _config['_reload'], function( _key, _val ){
            if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ) ){
                window.app[_key]();
            }
        });
    }

    initModulesInScope();
})($);